<template>
  <div class="delete-proforma-frais">
    <b-button
      v-if="permission && computedCheckedRowsProformat.length"
      size="sm"
      class="button-export-style pb-2"
      @click="handleShowConfirmAlert"
      title="Supprimer proforma"
    >
      <font-awesome-icon icon="trash"
    /></b-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    updateFunction: { required: true },
    permission: {
      required: true
    }
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    handleShowConfirmAlert() {
      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
      for (let j = 0; j < this.computedCheckedRowsProformat.length; j++) {
        htmlMessage =
          htmlMessage +
          '<li>' +
          this.computedCheckedRowsProformat[j].num +
          '</li>';
      }
      htmlMessage = htmlMessage + '</div></div>';
      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title:
            'Êtes-vous sur de generes les avoir(s) pour les facture(s) suivantes',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            for (let i = 0; i < this.computedCheckedRowsProformat.length; i++) {
              bodyFormData.append(
                'ids[' + [i] + ']',
                this.computedCheckedRowsProformat[i].id
              );
            }
            const response = await this.updateFunction({
              ids: bodyFormData,
              data: this.computedCheckedRowsProformat
            });
            if (response.succes) {
              this.$alert('', 'Proforma supprimé avec succès', 'success');
              this.$emit('refreshData');
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedCheckedRowsProformat() {
      return this.dataToUse
        .map(({ projects }) => projects)
        .flat()
        .filter(f => f.check == true && f.type_facture == 'Facture proforma');
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
