<template>
  <div class="facture_frais_dynamique">
    <div class="entete">
      <div class="box-all-filter">
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorFraisDyn" class="error">
            <ul v-if="Array.isArray(getErrorFraisDyn)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisDyn" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisDyn }}</div>
          </div>
        </div>
        <div class="box-label-champ" v-if="checkPermission('GGDFTHFTDDDDF')">
          <div class="label-box-style w-78-px">
            <span class="title-tabel">Type de date</span>
          </div>
          <b-form-select
            class="
              b-form-select-new-style
              bg-select
              w-5-px
              mt-2
              mb-2
              width-inputs-filtre
              pl-0
            "
            v-model="type_date"
            :options="[
              { value: 'statue_date', text: 'statut de paiement' },
              { value: 'date_depot_dossier', text: 'date de dépôt' }
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeTypeDate"
          ></b-form-select>
        </div>
        <filter-component
          label="Date début"
          classLabel="label-box-style w-78-px"
          v-if="checkPermission('GGDFTHFTDDDDF')"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              required
              :value="date_debut"
              @input="handleDateTimePicker($event, 'date_debut')"
              :disabled-date="disabledStartDate"
              input-class="custom-date-picker-filter"
              class="
                custom-date-picker-calender-filter
                width-inputs-filtre
                mt-2
                mb-2
              "
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          v-if="checkPermission('GGDFTHFTDDDDF')"
          label="Date fin"
          classLabel="label-box-style w-78-px"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              :value="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              :clear-button="true"
              :disabled-date="disabledEndDate"
              input-class="custom-date-picker-filter"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker> </template
        ></filter-component>
        <input-file-excel-num-dossier
          v-show="true"
          :value="nums"
          @searchWithNumDossier="searchWithMultipleFacture"
          :fetchFuntion="fecthFactureNumbersInExcelFile"
          :showCount="true"
        />
        <div
          v-if="getLoadingFraisDyn"
          class="chargement chargement-loading-icon mt-1 ml-3"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search
            v-if="checkPermission('GGDFTHCS')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num dossier"
          />
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Master F"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHFMF')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterMasterFiliale"
                  :options="[...getListMasterFraisDync]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListFraisDyn"
                  @input="handleChangeMasterFiliale"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} Maters</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>

            <filter-component
              label="Société"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHFV')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterSupport"
                  :options="[...getListSupportFraisDync]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListFraisDyn"
                  @input="handleChangeSupport"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Société'
                          : values.length > 1
                          ? ' Sociétés'
                          : ''
                      }}</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              label="Client"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHFCL')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterFiliale"
                  :options="[...getListInstallagteurFraisDync]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListFraisDyn"
                  @input="handleChangeFiliale"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Client'
                          : values.length > 1
                          ? ' Clients'
                          : ''
                      }}</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              label="Famille"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHFLL')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterFamilly"
                  :options="[...getListFamilleConfigFrias]"
                  :multiple="false"
                  track-by="id"
                  label="familly"
                  class="customSelectSingleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoadingFamilleConfig"
                  @input="handleChangeFamille"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} Familles</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              label="Statut Facture"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHSTAT')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterStatut"
                  :options="[...computedValidationList]"
                  :multiple="true"
                  label="full_name"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListFraisDyn"
                  @input="handleFilter"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} Statues</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              label="Statut installateur"
              classLabel="w-126-px"
              v-if="checkPermission('GGDFTHSTATIN')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterStatutInstallateur"
                  :options="[...computedValidationListInstallateur]"
                  :multiple="true"
                  label="full_name"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListFraisDyn"
                  @input="handleFilter"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} selected</span
                    ></template
                  >
                </multiselect>
              </template>
            </filter-component>
          </b-popover>
          <!-- delete facture proformat  -->
          <delete-proforma
            :dataToUse="getListProjectsFraisDync"
            :updateFunction="deleteProformaFraisAction"
            :permission="checkPermission('GGDFTHDFA')"
            @refreshData="handleFilter"
          />
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            :title="
              showTotal == true
                ? 'Masquer la ligne de total'
                : 'Afficher la ligne de total'
            "
            @click="showTotal = !showTotal"
          >
            <font-awesome-icon :icon="showTotal ? 'eye-slash' : 'eye'" />
          </b-button>
          <reglement
            class="mr-1"
            v-if="checkPermission('GGDFTHAR') && computedCheckedRowsOneClient"
            :dataToUse="computedCheckedRowsOneClientFacture"
          />
          <confirmation-multi
            v-if="
              checkPermission('GGDFTHCMF') && computedCheckedRowsMuli.length
            "
            @reloadData="reloadData"
            :dataToUse="computedCheckedRowsMuli"
          />
          <generation-facture-avoir
            v-if="checkPermission('GGDFTHGFA')"
            :interfaceName="this.$options.name"
            :dataToUse="computedCheckedRows"
            @reloadData="reloadData"
          />
          <transfert-to-libre
            v-if="checkPermission('GGDFTHTFAIFL')"
            :interfaceName="this.$options.name"
            :dataToUse="computedCheckRowsToTransfertLibre"
            :indexsToRemove="computedGetIndex"
          />
          <!-- checkPermission('GGDFTHMSPS') || -->
          <!-- <validation-statue-installateur
            v-if="checkPermission('GGDFTHMSIPS') && computedCheckedRows.length"
            class="mr-2"
            :dataToUse="getListProjectsFraisDync"
            @reloadData="reloadData"
            :statueRequired="checkPermission('GGDFTHMSPS') && false"
            :statueInstaRequired="checkPermission('GGDFTHMSIPS')"
          /> -->
          <export-dynamic
            v-if="checkPermission('GGDFTHEXLS') || checkPermission('GGDFTHEZP')"
            :filter="ComputedFilter"
            :selected="computedCheckRows"
            :exportXls="exportFactureDynamicXls"
            :exportZip="exportFactureDynamicZip"
            :famille="support"
            :zipRequired="checkPermission('GGDFTHEZP')"
            :xlsRequired="checkPermission('GGDFTHEXLS')"
          />
          <div v-if="checkPermission('GGDFTHEM')">
            <div
              v-if="getLoadingMailMasterFiliale"
              class="chargement chargement-loading-icon"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="
                !getLoadingMailMasterFiliale && computedDataToSend.length > 0
              "
            >
              <sendMail
                :dataToUse="computedDataToSend"
                @eventMailSendRefreshTable="handleFilter()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          type_date !== null ||
            date_debut !== null ||
            date_fin !== null ||
            searchValue !== null ||
            category_id !== null ||
            filterFamilly != null
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont: </span>
        <span class="item-filter-style" v-if="type_date !== null"
          >Type de date: {{ type_date | FormateFilter }}</span
        >
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début: {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin: {{ date_fin }}</span
        >
        <span v-if="searchValue !== null" class="item-filter-style"
          >Recherche: {{ searchValue }}</span
        >
        <span
          v-if="filterFamilly !== null && filterFamilly.familly"
          class="item-filter-style"
          >Famille: {{ filterFamilly.familly }}</span
        >
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation">
      <div v-if="filterMasterFiliale.length" class="block-filter">
        <span class="title-block-chip">Master : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterMasterFiliale"
          @input="handleFilter"
        >
          <vs-chip
            :key="masterF.name + 'master-filiale'"
            @click="
              remove(
                masterF,
                'filterMasterFiliale',
                'handleChangeMasterFiliale'
              )
            "
            v-for="masterF in filterMasterFiliale"
            closable
          >
            {{ masterF.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterSupport.length" class="block-filter">
        <span class="title-block-chip">Société : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterSupport"
          @input="handleFilter"
        >
          <vs-chip
            :key="support.name + 'statue'"
            @click="remove(support, 'filterSupport', 'handleChangeSupport')"
            v-for="support in filterSupport"
            closable
          >
            {{ support.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterFiliale.length" class="block-filter">
        <span class="title-block-chip">Client : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterFiliale"
          @input="handleFilter"
        >
          <vs-chip
            :key="client.name + 'client'"
            @click="remove(client, 'filterFiliale', 'handleChangeFiliale')"
            v-for="client in filterFiliale"
            closable
          >
            {{ client.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterStatut.length" class="block-filter">
        <span class="title-block-chip">Statut : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterStatut"
          @input="handleFilter"
        >
          <vs-chip
            :key="statue.full_name + 'statue'"
            @click="remove(statue, 'filterStatut', 'handleFilter')"
            v-for="statue in filterStatut"
            closable
          >
            {{ statue.full_name }}
          </vs-chip>
        </vs-chips>
      </div>
      <!-- <div v-if="filterFamilly" class="block-filter width-family-chip">
        <span class="title-block-chip">Famille : </span>
        <vs-chip
          placeholder="New Element"
          v-model="filterFamilly"
          :key="filterFamilly.familly + 'famille'"
          @click="removeFamillyFilter()"
          closable
        >
          {{ filterFamilly.familly }}
        </vs-chip>
      </div> -->
      <div v-if="filterStatutInstallateur.length" class="block-filter">
        <span class="title-block-chip">Statut installateur: </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterStatutInstallateur"
          @input="handleFilter"
        >
          <vs-chip
            :key="statue.full_name + 'statue'"
            @click="remove(statue, 'filterStatutInstallateur', 'handleFilter')"
            v-for="statue in filterStatutInstallateur"
            closable
          >
            {{ statue.full_name }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style tableGlobaleFraisDynamique table-header"
          :style="sizeTable"
          :items="getListProjectsFraisDync"
          :fields="computedDFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <span v-if="data.field.key != 'check_all'">
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template>
          <template v-slot:cell(name)="data">{{ data.item.name }}</template>
          <template v-slot:cell(numero_dossier)="">-</template>
          <template v-slot:cell(nom_prenom)="">-</template>
          <template v-slot:cell(min)="data"
            >{{ data.item.montant_final }} €</template
          >
          <template v-slot:cell(montant_net_ht)="data"
            >{{ data.item.montant_net_ht }} €</template
          >
          <template v-slot:cell(numero_facture)="">-</template>
          <template v-slot:cell(vendeur)="">-</template>
          <template v-slot:cell(client)="">-</template>
          <template v-slot:cell(ht)="data">{{ data.item.ht }} €</template>
          <template v-slot:cell(tva)="data"
            >{{ data.item.total_tva }} €</template
          >
          <template v-slot:cell(ttc)="data">{{ data.item.ttc }} €</template>
          <template v-slot:cell(date_facture)="">-</template>
          <template v-slot:cell(reste)="">-</template>
          <template v-slot:cell(statue_facture)="">-</template>
          <template v-slot:cell(statue_installateur)="">-</template>
          <!-- <template v-slot:cell(type_paiement)="">-</template> -->
          <template v-slot:cell(date_paiement)="">-</template>
          <template v-slot:cell(reglement)="">-</template>
          <template v-slot:cell(date_envoi)="">-</template>
          <template v-slot:cell(numero_lot)="">-</template>
          <template v-slot:cell(historique)="">-</template>
          <template v-slot:cell(numero_avoir)="">-</template>
          <template #row-details="data">
            <span
              v-for="(item, index) in computedProjectsWithSepareteLine(
                data.item.projects
              )"
              :key="data.item.name + index"
            >
              <template>
                <span
                  class="align-td-item border-botom background-week"
                  :class="{
                    'separate-line-style': item.type === 'separation'
                  }"
                >
                  <td class="item-details-style col-check-obligee">
                    <div
                      class="vertical-align"
                      :class="{
                        'color-som-text': item.type === 'separation',
                        'separate-line-style': item.type === 'separation'
                      }"
                      v-if="item.type === 'separation'"
                    >
                      {{ item.count }}
                    </div>
                    <div class="mt-1 mb-1" v-else>
                      <b-form-checkbox
                        v-model="item.check"
                        @change="checkAllVerifDetails(data.item, item)"
                        class="check-th-details ml-2"
                        :value="true"
                        :unchecked-value="false"
                      >
                      </b-form-checkbox>
                    </div>
                  </td>

                  <td
                    class="item-details-style col-plus-obligee"
                    :class="{
                      'separate-line-style': item.type === 'separation'
                    }"
                  >
                    <span>
                      <!-- <font-awesome-icon icon="info" class="icon-info-show"
                    /> -->
                    </span>
                  </td>
                  <td
                    class="item-details-style num-lot-td"
                    :class="{
                      'color-som-text': item.type === 'separation',
                      'separate-line-style': item.type === 'separation'
                    }"
                  >
                    {{ item.objet }}
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACNDD')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.numero_dossier }}
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACNEP')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.nom_prenom }}
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACMM')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.montant_final }} €
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACMHTD')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.montant_net_ht }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCNDF')"
                  >
                    {{ item.num }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCNFA')"
                  >
                    <div class="center-th-montant">
                      <font-awesome-icon
                        :id="`popover-1-avoir-${data.item.name}-${index}`"
                        v-show="
                          item.FactureAvoir && item.FactureAvoir.length != 0
                        "
                        icon="info"
                        class="
                          info-icon-commercial-lot
                          content-tooltip-style
                          w-25
                        "
                      />
                      <avoir-facture
                        v-if="
                          item.type_facture != 'Facture proforma' &&
                            item.etat != 'Annulée' &&
                            item.type !== 'separation'
                        "
                        :item="item"
                        :permission="checkPermission('GGDFTHGDAP')"
                        :index="data.item.name + index"
                      />
                      <b-popover
                        :target="`popover-1-avoir-${data.item.name}-${index}`"
                        :triggers="computedPopoverTriggers"
                        custom-class="avoir-tooltip custom-z-index-popover"
                      >
                        <div v-for="(facture, i) in item.FactureAvoir" :key="i">
                          <div class="d-flex align-items-center">
                            <div class="d-inline-block">
                              {{ facture.num }} , {{ facture.total_ttc }} €

                              <font-awesome-icon
                                v-if="checkPermission('GGDFTHTAF')"
                                icon="arrow-alt-circle-down"
                                class="
                                  icon-style-color
                                  mr-1
                                  button-export-style-avoir
                                "
                                @click="clickButton(facture.id)"
                              />
                              <font-awesome-icon
                              v-if="checkPermission('GGDFTHTFA')"
                                icon="eye"
                                @click.prevent="handleShowFacture(facture.id)"
                                title="visualiser la facture"
                                class="icon-style-color  mr-1"
                              />
                              <!-- <font-awesome-icon
                              v-if="checkPermission('GAPTHVFAP')"
                              icon="file-code"
                              class="icon-style-color ml-1"
                              @click="
                                handleOpenAvoirVusialisationModal(
                                  prime,
                                  'prime'
                                )
                              "
                            /> -->
                              <!-- <Historique
                              :awesome="true"
                              :permission="checkPermission('GAPTHAHPR')"
                              :dataToUse="prime.logs"
                              :index="prime.id"
                              @returnHistorique="returnHistorique"
                            /> -->
                            </div>
                          </div>
                        </div>
                      </b-popover>
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCV')"
                  >
                    {{ item.vendeur }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHACCL')"
                  >
                    {{ item.client }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCCF')"
                  >
                    {{ item.coef }}
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACMHT')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.ht }} €
                  </td>
                  <td
                    v-if="checkPermission('GGDFTHACTVA')"
                    class="item-details-style"
                    :class="{ 'color-som-text': item.type === 'separation' }"
                  >
                    {{ item.total_tva }} €
                  </td>
                  <td
                    class="item-details-style montant-width-class"
                    v-if="checkPermission('GGDFTHACMTTC')"
                  >
                    <template-frais
                      :item="item"
                      :data="item.data"
                      :project="item"
                      :componentName="$options.name"
                      :editable="
                        item.etat == 'Payé' ||
                        item.etat == 'Annulée' ||
                        item.modifiable == false
                          ? false
                          : checkPermission('GGDFTHVF')
                          ? true
                          : false
                      "
                      :download="item.etat == 'Annulée' ? false : item.id"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCDDF')"
                  >
                    {{ item.date }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCRAP')"
                  >
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else>{{ item.reste }} €</div>
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCSDF')"
                  >
                    <!-- {{ item.etat }} -->
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else>
                      <EditableInput
                        v-if="item.type !== 'separation'"
                        :editable="
                          checkPermission('GGDFTHMSPF') &&
                            item.etat != 'Annulée' &&
                            item.etat != 'Payé' &&
                            item.type_facture != 'Facture proforma' &&
                            item.modifiable == true &&
                            false
                        "
                        champName="etat"
                        editableType="select"
                        :optionsSelect="computedValidationListComponent"
                        :item="{
                          item: item,
                          id_to_use: item.id
                        }"
                        :value="item.etat"
                        :updateFunction="updateStatueaFraisDynamic"
                        label="full_name"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                        :showLabels="false"
                      />
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCSI')"
                  >
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else>
                      <EditableInput
                        :editable="
                          checkPermission('GGDFTHMSIPF') &&
                            item.etat != 'Annulée' &&
                            item.etat != 'Payé' &&
                            item.statut_client != 'Payé' &&
                            item.type_facture != 'Facture proforma' &&
                            false
                        "
                        champName="statut_client"
                        editableType="select"
                        :optionsSelect="computedValidationListInstallateur"
                        :item="{
                          item: item,
                          id_to_use: item.id
                        }"
                        :value="item.statut_client"
                        :updateFunction="updateStatueInstallateuraFraisDynamic"
                        label="full_name"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                        :showLabels="false"
                        classToUse="color-som-text"
                      />
                    </div>
                  </td>
                  <!-- <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCTDP')"
                  >
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else>
                      <paiement-column-component
                        :editable="computedCreateLcr(item) && false"
                        champName="id"
                        :item="item"
                        :value="item.type_paiement"
                        :updateFunction="updattypePaiementFraisDynamic"
                      />
                    </div>
                  </td> -->
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCDP')"
                  >
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else>
                      {{ item.date_paiement }}
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCRG')"
                  >
                    <div v-if="item.type === 'separation'">-</div>
                    <div v-else class="">
                      {{ item.montant_regle }} €
                      <font-awesome-icon
                        v-show="item.reglements.length"
                        :id="`popover-1-${index}`"
                        icon="info"
                        class="
                          info-icon-commercial-lot
                          content-tooltip-style
                          w-25
                        "
                      />

                      <b-popover
                        :target="`popover-1-${index}`"
                        :triggers="computedPopoverTriggers"
                        :custom-class="
                          'avoir-tooltip custom-z-index-popover ' + stylePopover
                        "
                      >
                        <b-table-simple class="table-fiche">
                          <b-tbody>
                            <b-tr class="title-ligne">
                              <b-th class="newcolor">#</b-th>
                              <b-th class="newcolor">Montant reglé</b-th>
                              <b-th class="newcolor">Date de paiement</b-th>
                              <b-th class="newcolor">Type de paiement</b-th>
                              <b-th
                                class="newcolor"
                                v-if="checkPermission('GGDFTHUR')"
                              ></b-th>
                            </b-tr>
                            <template v-for="(reglement, i) in item.reglements">
                              <b-tr :key="'reglement' + i">
                                <b-td class="newcolor"
                                  >{{ reglement.payment_id }}
                                </b-td>
                                <b-td class="newcolor"
                                  >{{ reglement.amount }} €</b-td
                                >
                                <b-td class="newcolor">{{
                                  formateDateGetters(reglement.payment_date)
                                }}</b-td>
                                <b-td class="newcolor">{{
                                  reglement.payment_type
                                }}</b-td>
                                <b-td
                                  class="newcolor"
                                  v-if="checkPermission('GGDFTHUR')"
                                  ><update-reglement-modal
                                    :item="reglement"
                                    dataInterface="frais"
                                    @openModal="triggersPop = 'click'"
                                    @closeModal="triggersPop = 'hover'"
                                /></b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </b-popover>
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHACDEM')"
                  >
                    {{ item.email_sent_at }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCNL')"
                  >
                    {{ item.lot }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('GGDFTHCH')"
                  >
                    <Historique
                      :awesome="true"
                      :index="item.id"
                      :permission="checkPermission('GGDFTHCH')"
                      :dataToUse="item.historique"
                    />
                  </td>
                </span>
              </template>
              <!-- <template v-if="item.showDetails == true"> -->

              <!-- <span
                  v-for="(facture, index) in item.factures"
                  :key="'facture' + facture + index"
                  class="align-td-item border-botom background-depot"
                  ><td class="item-details-style col-check-obligee">
                    <b-form-checkbox
                      v-model="facture.check"
                      @change="
                        checkAllVerifDetailsFacture(
                          data.item,
                          item,
                          facture,
                          index
                        )
                      "
                      class="check-th-details ml-2"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </td>
                </span -->
              <!-- </template> -->
            </span></template
          >
        </b-table>
        <div class="footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getListProjectsFraisDyncRows"
            :per-page="per_page"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="per_page"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div
        v-if="
          computedTotal &&
            (computedTotal.ht > 0 ||
              computedTotal.ttc > 0 ||
              computedTotal.someApaye > 0) &&
            checkPermission('GGDFTHABT')
        "
        class="style-somme-th-ttc"
      >
        <p class="m-2">
          Total ht:
          {{   computedTotal.ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
          € {{ '   ' }}Total ttc:

          {{
            computedTotal.ttc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € Total à payer :
          {{
            computedTotal.someApaye
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          €
        </p>
      </div>
    </div>
    <b-modal
          ref="visualisationFactureModal"
          id="visualisationFactureModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Afficher Facture</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('visualisationFactureModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <iframe
                height="750"
                width="700"
                :src="factureToShow + '#toolbar=0'"
                scrolling="no"
              ></iframe>
            </template>
          </Card>
        </b-modal>
  </div>
  
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import FilterComponent from '../component/filterComponent.vue';
import ExportDynamic from './component/exportDynamic.vue';
import EditableInput from '@/views/component/EditableInput.vue';
import deleteProforma from './component/deleteProforma.vue';
// import paiementColumnComponent from '../component/paiementColumnComponent.vue';
export default {
  name: 'Gestion-globale-des-frais',
  data() {
    return {
      factureToShow : null ,
      triggersPop: 'hover',
      showTotal: true,
      support: 'support',
      checkAll: false,
      date_fin: null,
      date_debut: null,
      type_date: 'statue_date',
      filterFiliale: [],
      filterFamilly: null,
      filterMasterFiliale: [],
      filterStatut: [
        { value: 'A payer', full_name: 'A payer' },
        {
          value: 'Créé',
          full_name: 'Créé'
        },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ],
      // filterStatut: [],
      filterStatutInstallateur: [],
      searchValue: null,
      name: null,
      filterSupport: [],
      page: 1,
      per_page: 20,
      perPageList: [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      nums: null
    };
  },
  filters: {
    FormateFilter: value => {
      switch (value) {
        case 'statue_date':
          return 'statut de paiement';
        case 'date_depot_dossier':
          return 'date de dépôt';
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingMailMasterFiliale',
      'getLoadingFraisDyn',
      'getErrorFraisDyn',
      'getListSupportFraisDync',
      'getLoaderDataFilterListFraisDyn',
      'getListInstallagteurFraisDync',
      'getListMasterFraisDync',
      'getListProjectsFraisDync',
      'getListProjectsFraisDyncRows',
      'getLoadingFamilleConfig',
      'getListFamilleConfigFrias',
      'isSuperAdminGetter',
      'checkPermission',
      'formateDateGetters',
      'getUserData',
      'getCategories',
      'getCategoriesDetailsLoading'
    ]),
    computedStyle() {
      return this.showPopover == true ? 'd-none' : '';
    },
    computedPopover() {
      return this.showPopover == true ? 'click' : 'hover';
    },
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);
      if (this.getListProjectsFraisDync.length) {
        this.getListProjectsFraisDync.forEach(element => {
          element.projects.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type_facture != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      if (this.getListProjectsFraisDync.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);
        this.getListProjectsFraisDync.forEach(element => {
          element.projects.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type_facture != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedDFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'name',
          label: 'Filiale Installateur',
          thClass: 'num-lot-th',
          tdClass: 'num-lot-td',
          show: true
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          show: this.checkPermission('GGDFTHACNDD')
        },
        {
          key: 'nom_prenom',
          label: 'Nom et prénom',
          show: this.checkPermission('GGDFTHACNEP')
        },
        {
          key: 'min',
          label: 'Montant MIN',
          show: this.checkPermission('GGDFTHACMM')
        },
        {
          key: 'montant_net_ht',
          label: 'Montant HT dossier',
          show: this.checkPermission('GGDFTHACMHTD')
        },
        {
          key: 'numero_facture',
          label: 'Numéro de facture',
          show: this.checkPermission('GGDFTHCNDF')
        },
        {
          key: 'numero_avoir',
          label: 'Numéro de facture avoir',
          show: this.checkPermission('GGDFTHCNFA')
        },
        {
          key: 'vendeur',
          label: 'Société',
          show: this.checkPermission('GGDFTHCV')
        },
        {
          key: 'client',
          label: 'Client',
          show: this.checkPermission('GGDFTHACCL')
        },
        {
          key: 'coeff',
          label: 'Coefficient',
          show: this.checkPermission('GGDFTHCCF')
        },
        {
          key: 'ht',
          label: 'Montant HT',
          show: this.checkPermission('GGDFTHACMHT')
        },
        { key: 'tva', label: 'Tva', show: this.checkPermission('GGDFTHACTVA') },
        {
          key: 'ttc',
          label: 'Montant TTC',
          show: this.checkPermission('GGDFTHACMTTC'),
          thClass: 'montant-width-class',
          tdClass: 'montant-width-class'
        },
        {
          key: 'date_facture',
          label: 'Date de facture',
          show: this.checkPermission('GGDFTHCDDF')
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          show: this.checkPermission('GGDFTHCRAP')
        },
        {
          key: 'statue_facture',
          label: 'Statut de facture',
          show: this.checkPermission('GGDFTHCSDF')
        },
        {
          key: 'statue_installateur',
          label: 'Statut installateur',
          show: this.checkPermission('GGDFTHCSI')
        },
        // {
        //   key: 'type_paiement',
        //   label: 'Type de paiement',
        //   show: this.checkPermission('GGDFTHCTDP')
        // },
        {
          key: 'date_paiement',
          label: 'Date de paiement',
          show: this.checkPermission('GGDFTHCDP')
        },
        {
          key: 'reglement',
          label: 'Reglement',
          show: this.checkPermission('GGDFTHCRG')
        },
        {
          key: 'date_envoi',
          label: "Date d'envoie",
          show: this.checkPermission('GGDFTHACDEM')
        },
        {
          key: 'numero_lot',
          label: 'Numéro de lot',
          show: this.checkPermission('GGDFTHCNL')
        },
        {
          key: 'historique',
          label: 'Historique',
          show: this.checkPermission('GGDFTHCH')
        }
      ];
      return fields.filter(item => item.show == true);
    },
    // formatedNumber() {
    //   return function(num) {
    //     returnNumber(num.toFixed(0)).toLocaleString() +
    //       '.' +
    //       Number(
    //         num.toString().slice(num.toString().indexOf('.') + 1)
    //       ).toLocaleString();
    //   };
    // },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].projects.length; j++) {
          if (
            this.computedCheckedRows[i].projects[j].check == true &&
            this.computedCheckedRows[i].projects[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].projects[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    TopFilter() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 75 + heigthBlock;
      return 'top : ' + `${hei}` + 'px!important ;';
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterStatutInstallateur.length > 0 ||
        this.filterStatut.length > 0 ||
        this.filterMasterFiliale.length > 0 ||
        this.filterFiliale.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedProjectsWithSepareteLine() {
      return function(data) {
        let arrayProjectWithseparate = [];
        let sommeHt = 0;
        let sommettc = 0;
        let sommeTva = 0;
        let j = 0;
        let index = 0;
        let count = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.numero_dossier === data[i + 1]?.numero_dossier) {
            arrayProjectWithseparate.push(data[i]);
            sommeHt += data[i].ht_float;
            sommettc += data[i].ttc_float;
            sommeTva += data[i].total_tva_float;
            j++;
            count++;
            data[i].index = index;
          } else {
            arrayProjectWithseparate.splice(j, 0, data[i]);
            sommeHt += data[i].ht_float;
            sommettc += data[i].ttc_float;
            sommeTva += data[i].total_tva_float;
            data[i].index = index;
            count++;
            j++;
            index++;
            arrayProjectWithseparate.splice(j, 0, {
              nom_prenom: data[i].nom_prenom,
              montant_final: data[i].montant_final,
              montant_net_ht: data[i].montant_net_ht,
              numero_dossier: data[i].numero_dossier,
              mode_paiement: '-',
              lot: '-',
              etat: '-',
              be_type: '-',
              avoir_id: '-',
              avoir_num: '-',
              client: '-',
              client_email: '-',
              date: '-',
              ht: (Math.round(sommeHt * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              num: '-',
              objet: '-',
              project_id: '-',
              regie: '-',
              statut_client: '-',
              super_regie: '-',
              // index: '-',
              total_tva: (Math.round(sommeTva * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              template_id: '-',
              ttc: (Math.round(sommettc * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              type_facture: '-',
              vendeur: '-',
              vendeur_id: '-',
              type_paiement: '-',
              type: 'separation',
              count: count
            });
            count = 0;
            j++;
            sommeHt = 0;
            sommettc = 0;
            sommeTva = 0;
            
          }
        }
        return arrayProjectWithseparate.filter(item =>
          this.showTotal == false ? item.type != 'separation' : item
        );
      };
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].projects.length; j++) {
          if (
            this.computedCheckedRows[i].projects[j].check == true &&
            this.computedCheckedRows[i].projects[j].avoir_id === null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].projects[j].ht
                      .replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].projects[j].ttc
                      .replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].projects[j].type_facture ==
                'Facture' &&
              (this.computedCheckedRows[i].projects[j].etat == 'A payer' ||
                this.computedCheckedRows[i].projects[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].projects[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j].reste +
                        ''.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedCheckedRows() {
      let tableF = this.getListProjectsFraisDync?.filter(k => {
        return k.projects.some(e => e.check == true);
      });
      return tableF;
    },
    computedCheckedRowsMuli() {
      let tableF = this.getListProjectsFraisDync?.filter(k => {
        return k.projects.some(e => e.check == true && e.modifiable == true);
      });
      return tableF;
    },
    computedCreateLcr: function() {
      return function(data) {
        if (data) {
          if (
            this.checkPermission('GGDFTHMTP') &&
            data.modifiable == true &&
            data['etat'] != 'Payé' &&
            data['etat'] != 'Annulée' &&
            data['type_facture'] != 'Facture proforma'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedValidationList() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Annulée', full_name: 'Annulée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' },
        {
          value: 'Créé',
          full_name: 'Créé'
        }
      ];
    },
    computedValidationListComponent() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Annulée', full_name: 'Annulée', $isDisabled: true },

        {
          value: 'Créé',
          full_name: 'Créé',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ];
    },
    computedOneSupport() {
      // return true;
      return this.filterSupport.length == 1;
    },
    ColorSupportComputed() {
      return function(support, principale) {
        if (support.bgColor && principale) {
          return {
            'background-color': support.bgColor + '!important',
            color: '#fff'
          };
        } else if (support.bgColor && !principale) {
          return { 'background-color': support.bgColor + '!important' };
        } else {
          return { 'background-color': 'f0f1ff !important' };
        }
      };
    },
    ComputedFilter() {
      return {
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        filterMasterFiliale: this.filterMasterFiliale,
        filterSupport: this.filterSupport,
        filterFiliale: this.filterFiliale,
        filterFamilly: this.filterFamilly,
        filterStatut: this.filterStatut,
        filterStatutInstallateur: this.filterStatutInstallateur,
        search: this.searchValue,
        type_date: this.type_date
      };
    },
    computedCheckRows() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (project.check == true) {
            selected.push(project.id);
          }
        });
      });
      return selected;
    },
    computedDataToSend() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (
            project.check == true &&
            project.etat != 'Créé' &&
            project.modifiable == true &&
            project.type_facture != 'Facture proforma'
          ) {
            selected.push(project);
          }
        });
      });
      return selected;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (project.check == true && project.avoir_id !== null) {
            selected.push(project.id);
          }
        });
      });
      return selected;
    },
    computedValueTypeDateSelected() {
      return function(type) {
        this.computedValidationListInstallateur.map(item => {
          if (item.value === type) {
            return item.full_name;
          }
        });
      };
    }
  },
  methods: {
    ...mapActions([
      'getAllDynamicFactureFrais',
      'getAllDataForFilterDynamicFrais',
      'fetchAllFammilleConfig',
      'updateStatueInstallateuraFraisDynamic',
      'updateStatueaFraisDynamic',
      'updattypePaiementFraisDynamic',
      'getAllFactureForDynamicProject',
      'exportFactureDynamicZip',
      'exportFactureDynamicXls',
      'fetchAllCategories',
      'exportPdfFactureaFraisDynamic',
      'deleteProformaFraisAction',
      'fecthFactureNumbersInExcelFile',
      'displayFact'
    ]),
    hideModal(ref)
    {
      this.$refs[ref].hide();
    },
    resetModal()
    {
       this.factureToShow = null 
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    clickButton(id) {
      this.exportPdfFactureaFraisDynamic({
        id: id
      });
    },
    handleChangeFamille() {
      this.handleFilter();
    },
    removeFamillyFilter() {
      this.filterFamilly = null;
      this.handleFilter();
    },
    handleChangeTypeDate() {
      this.handleFilter();
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    getDataForRowProjects(data, item) {
      item.showDetails = !item.showDetails;
      // if (item.showDetails == true) {
      //   this.getAllFactureForDynamicProject({
      //     filterSupport: this.filterSupport,
      //     category_id: this.category_id,
      //     id: item.id,
      //     item: item
      //   });
      // }
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    checkAllFunction() {
      this.getListProjectsFraisDync.map(item => {
        item.check = this.checkAll;
        item.projects.map(project => {
          project.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(project => {
        project.check = item.check;
      });
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == true && this.checkAll == false) {
        this.checkAll = true;
      }

      // item.factures.map(facture => {
      //   facture.check = item.check;
      // });
    },
    checkAllVerifDetailsFacture(data, item, facture, index) {
      if (facture.check == false && data.check == true) {
        data.check = false;
      }
      if (facture.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (facture.check == false && item.check == true) {
        item.check = false;
      }
    },

    getDataForRow(data) {
      data.toggleDetails();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    getAllDataForFilterDynamicFraisAction(item) {
      this.getAllDataForFilterDynamicFrais({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        masters: this.filterMasterFiliale.map(item => item.id),
        refreshMaster: item == 'master' ? false : true
      });
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterDynamicFraisAction();
      this.filterFiliale = [];
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    setLocalStorageSimulationFraisFacture() {
      localStorage.setItem(
        'frais-dynamique',
        JSON.stringify({
          type_date: this.type_date,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filterFiliale: this.filterFiliale,
          filterFamilly: this.filterFamilly,
          filterMasterFiliale: this.filterMasterFiliale,
          search: this.searchValue,
          filterSupport: this.filterSupport,
          name: this.name
        })
      );
    },
    handleChangeCategory() {
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.handleFilter();
    },
    handleChangeMasterFiliale() {
      this.getAllDataForFilterDynamicFraisAction('master');
      this.handleFilter();
    },
    handleChangeSupport() {
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    // changeSearchNumValue(value) {
    //   this.name = value;
    //   this.handleFilter();
    // },
    handleFilter() {
      this.setLocalStorageSimulationFraisFacture();
      this.getAllDynamicFactureFrais({
        page: this.page,
        per_page: this.per_page,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        search: this.searchValue,
        name: this.name,
        filterFiliale: this.filterFiliale,
        filterMasterFiliale: this.filterMasterFiliale,
        filterSupport: this.filterSupport,
        filterFamilly: this.filterFamilly,
        statut: this.filterStatut,
        statue_installateur: this.filterStatutInstallateur,
        type_date: this.type_date,
        nums: this.nums
      });
    },
    handlechangeFilialeSupport() {
      this.filterStatut = [];
      this.filterStatutInstallateur = [];
      this.handleFilter();
    },
    rowClass(item, type) {
      return 'ligneNormale';
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    templateFrais: () => import('./component/templateFrais.vue'),
    confirmationMulti: () => import('./component/confirmationMulti.vue'),
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    Card: () => import('@/views/component/card.vue'),
    // ValidationStatueInstallateur: () =>
    //   import('./component/ValidationStatueInstallateur.vue'),
    generationFactureAvoir: () =>
      import('./component/generationFactureAvoir.vue'),
    transfertToLibre: () => import('./component/transfertToLibre.vue'),
    EditableInput,
    FilterComponent,
    ExportDynamic,
    // paiementColumnComponent,
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    updateReglementModal: () =>
      import('@/views/component/reglement/updateReglementModal.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    avoirFacture: () => import('./component/avoirFacture.vue'),
    deleteProforma,
    InputFileExcelNumDossier: () =>
      import(
        '@/views/ThProject/componentRapportTh/inputFileExcelNumDossier.vue'
      )
  },
  async mounted() {
    if (localStorage.getItem('frais-dynamique')) {
      let local = JSON.parse(localStorage.getItem('frais-dynamique'));
      this.date_debut = local.date_debut;
      this.filterMasterFiliale = local.filterMasterFiliale;
      this.date_fin = local.date_fin;
      this.filterFiliale = local.filterFiliale;
      this.filterSupport = local.filterSupport;
      this.filterFamilly = local.filterFamilly ? local.filterFamilly : null;
      this.type_date = local.type_date;
    } else {
      var now = moment();
      this.date_debut = moment(now)
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.setLocalStorageSimulationFraisFacture();
    }

    this.fetchAllFammilleConfig({ blocked: false });
    this.fetchAllCategories();
    this.getAllDataForFilterDynamicFraisAction();
    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.width-inputs-filtre {
  width: 128px !important;
}
.width-family-chip {
  width: auto !important;
}

.facture_frais_dynamique {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 172px);
    .tableGlobaleFraisDynamique {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
}
.style-somme-th-ttc {
  font-family: 'Montserrat', sans-serif;
  background-color: #d0e4f5;
  position: absolute;
  left: 3px;
  bottom: 2px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
  box-shadow: rgb(0 0 0 / 18%) 0px 4px 12px;
  text-shadow: 0 0 black;
}
.vertical-align {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss">
.button-export-style-avoir {
  color: #e4261b;
  &:hover,
  &:focus {
    color: #e4261b;
  }
}
.separate-line-style {
  background-color: #b0afd9 !important;
  height: 30px;
}
.other-line1 {
  background-color: #f2f2f2 !important;
}
.other-line2 {
  background-color: #d0d0e0 !important;
}
// .table-rapport-style .tableGlobaleFraisDynamique td {
//   width: calc(100vh / 4);
//   line-height: 10px;
//   span {
//     cursor: pointer;
//   }
// }
.table-rapport-style .tableGlobaleFraisDynamique {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      // background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd985;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #cdcce2 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;
      color: black;
      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
.color {
  border-left: none !important;
}
.montant-width-class {
  min-width: 178px !important;
}
.custom-z-index-popover {
  z-index: 500;
}
</style>
